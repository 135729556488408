@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);
body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, h1, p{
    margin: 0%;
}

a{
    color: white;
}

h2{
   margin-top: 1vh; 
}

body::-webkit-scrollbar{
    display: none;
}

html{
    -ms-overflow-style: none; 
    scrollbar-width: none;
}

.home-wrapper{
    position: relative;
    width: 100%;
}

.screen1, .screen2, .screen3{
    width: 100%;
    height: 100vh;
    position: relative;
}

.screen2, .screen3{
    background-color: rgba(0, 0, 0, 0.281);
}

.header{
    height: 10vh;
    width: 100%;
    position: relative;
}

.header-text-wrapper{
    width: 35%;
    height: 100%;
    margin-left: 60%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    font-size: calc(0.6vh + 0.6vw);
    font-weight: bold;
    color: white;
    text-shadow: black 2px 2px 0;
}

.title-wrapper{
    width: 100%;
    margin-top: 15vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.boreas-title, .boreas-subtitle{
    font-weight: bold;
    color: white;
    font-size: calc(6vh + 6vw);
    font-family: 'Dancing Script', cursive;
    text-shadow: black 5px 5px 0;
}

.subtitle-wrapper{
    width: 100%;
    margin-top: 0vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.boreas-subtitle{
    font-size: calc(2vh + 2vw);
}

h3{
    font-size: calc(1vh + 1vw);
    text-shadow: none;
    color: white;
}

.product-wrapper{
    height: 60vh;
    width: 85%;
    margin-left: 5%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    background-color: rgba(0, 0, 0, 0.479);
    border: white solid 2px;
}

.product-image-wrapper{
    height: 90%;
    width: 45%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}

.product-image-wrapper > img{
    height: 100%;
}

.product-text-wrapper{
    height: 100%;
    width: 45%;
}

.product-text{
    color: white;
    line-height: 3vh;
    font-size: calc(0.6vh + 0.6vw);
}

.reservation-div{
    margin-top: 5vh;
    margin-left: 5%;
    height: 10%;
    width: 90%;
    border: white solid 1px;
    background-color: black;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.reservation-div > p{
    color: white;
    font-size: calc(0.85vh + 0.85vw);
}

#bg1{
    width: 100%;
    position: fixed;
    top: -20vh;
    left: 0;
    z-index: -1;
    -webkit-filter: sepia(30%) brightness(100%);
            filter: sepia(30%) brightness(100%);
    pointer-events: none;
}

.arrow-wrapper{
    height: 10vh;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    position: absolute;
    left: 0;
    top: 90vh;
}

.arrow{
    fill: white;
    height: 80%;
    -webkit-animation-name: arrow-anim;
            animation-name: arrow-anim;
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

.arrowup{
    fill: white;
    height: 70%;
    position: absolute;
    top: 1vh;
    left: 48.3%;
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
    -webkit-animation-name: arrowup-anim;
            animation-name: arrowup-anim;
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

@-webkit-keyframes arrow-anim {
    0%{
        margin-top: 0;
    }
    40%{
        margin-top: 0;
    }
    50%{
        margin-top: 2vh;
    }
    60%{
        margin-top: 0;
    }
    100%{
        margin-top: 0;
    }
}

@keyframes arrow-anim {
    0%{
        margin-top: 0;
    }
    40%{
        margin-top: 0;
    }
    50%{
        margin-top: 2vh;
    }
    60%{
        margin-top: 0;
    }
    100%{
        margin-top: 0;
    }
}

@-webkit-keyframes arrowup-anim {
    0%{
       top: 1vh;
    }
    40%{
        top: 1vh;
    }
    50%{
        top: -1vh;
    }
    60%{
        top: 1vh;
    }
    100%{
        top: 1vh;
    }
}

@keyframes arrowup-anim {
    0%{
       top: 1vh;
    }
    40%{
        top: 1vh;
    }
    50%{
        top: -1vh;
    }
    60%{
        top: 1vh;
    }
    100%{
        top: 1vh;
    }
}
